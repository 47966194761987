<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg12>
        <v-card tile flat>
          <v-layout wrap justify-start pa-4>
            <v-flex xs12 pt-6 pb-4 align-self-center text-left>
              <span style="font-family: poppinsbold; font-size: 20px">
                Unit Details
              </span>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Name </span>

              <v-text-field
                v-model="unitData.name"
                hide-details
                placeholder="Name"
                readonly
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold">Type</span>

              <v-select
                hide-details
                v-model="unitData.type"
                :items="types"
                dense
                readonly
                placeholder="Select Type"
              ></v-select>
            </v-flex>

            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type != 'Ic' && unitData.type != 'Entry Point'"
            >
              <span style="font-family: poppinsbold">IC</span>

              <v-select
                hide-details
                v-model="unitData.icId"
                :items="icList"
                item-text="name"
                item-value="_id"
                readonly
                dense
                placeholder="Select IC"
              ></v-select>
            </v-flex>
            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type == 'Transport Vehicle'"
            >
              <span style="font-family: poppinsbold"> No. of Seats </span>

              <v-text-field
                v-model="unitData.noOfSeats"
                hide-details
                readonly
                type="number"
                placeholder="No. of Seats"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type == 'Transport Vehicle'"
            >
              <span style="font-family: poppinsbold"> Register Number </span>

              <v-text-field
                v-model="unitData.regNo"
                hide-details
                readonly
                maxlength="10"
                placeholder="Register Number"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Description </span>

              <v-text-field
                readonly
                hide-details
                v-model="unitData.description"
                placeholder="Description"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 py-6>
              <v-divider></v-divider>
            </v-flex>

            <v-flex xs4 sm9 px-2 py-4 align-self-start text-left>
              <span style="font-size:19px;font-family: poppinsbold">
                Employee Details
              </span>
            </v-flex>
            <v-flex xs8 sm3 px-2 py-4 align-self-start text-left>
              <v-dialog v-model="assignDialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    style="font-family: poppinsmedium"
                    dark
                    block
                    
                    class="rounded-xl"
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                  >
                  <v-icon small>mdi-plus</v-icon> &nbsp;
                    <span>Assign Employee</span>
                  </v-btn>
                </template>
                <v-card class="pa-6">
                  <v-layout wrap>
                    <v-flex xs12 pa-2 align-self-center text-center>
                      <span
                        style="border-bottom:2px solid #000;font-size:20px"
                        class="poppinssemibold"
                        >Assign Employee</span
                      >
                    </v-flex>
                    <v-flex xs12 pa-6>
                      <v-select
                        hide-details
                        v-model="empid"
                        :items="empList"
                        item-text="userName"
                        item-value="_id"
                        dense
                        outlined
                        class="rounded-xl"
                        label="Select Employee"
                        multiple
                        placeholder="Select Employee"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error darken-1"
                      small
                      outlined
                      tile
                      dark
                      @click="assignDialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="primary darken-1"
                      small
                      tile
                      dark
                      @click="(assignDialog = false), assign()"
                      >Save</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-flex xs12 pa-2 v-if="unitData.unitincharge">
              <v-layout wrap v-if="unitData.unitincharge.length > 0">
                <v-flex
                  xs12
                  sm4
                  pa-2
                  v-for="(item, i) in unitData.unitincharge"
                  :key="i"
                >
                  <v-card outlined elevation="1" class="rounded-xl">
                    <v-layout wrap pb-8>
                      <v-flex xs12 class="" align-self-start text-right>
                        <v-dialog
                          v-model="item.removeDialog"
                          persistent
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn title="Remove Employee" x-small fab color="error" v-on="on" v-bind="attrs">
                              <v-icon
                                style="font-family: poppinsmedium"
                                dark
                                block
                                depressed
                                class="rounded-xl"
                                color="white"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-card class="pa-6">
                            <v-layout wrap>
                              <v-flex xs12 pa-2 align-self-center text-center>
                                <span
                                  style=" font-size:20px"
                                  class="poppinssemibold"
                                  >Do you want to remove this Employee?</span
                                >
                              </v-flex>
                            </v-layout>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="success darken-1"
                                small
                                outlined
                                tile
                                dark
                                @click="item.removeDialog = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="error darken-1"
                                small
                                tile
                                dark
                                @click="
                                  (item.removeDialog = false), remove(item._id)
                                "
                                >Remove</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                      <v-flex xs12 pa-2>
                        <router-link :to="'/Admin/viewEmployee?id=' + item._id">
                          <v-icon x-large>mdi-account-box</v-icon> &nbsp;
                        
                        </router-link>
                      </v-flex>
                      <v-flex xs12 pa-0>
                        <router-link :to="'/Admin/viewEmployee?id=' + item._id">
                          <span class="poppinssemibold">
                            {{ item.userName }}
                          </span>
                        </router-link>
                      </v-flex>

                       <v-flex xs12 pa-2>
                        <router-link :to="'/Admin/viewEmployee?id=' + item._id">
                           <span style="font-size:14px" class="poppinsregular">
                            Phone : {{ item.phoneNumber }}
                          </span>
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap v-else>
                 <v-flex xs12 pa-10 >
              <span class="poppinsregular">No Employee found!</span>
            </v-flex>
              </v-layout>
            </v-flex>
           
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      menu: false,
      menu1: false,
      units: null,
      program: "",
      types: null,
      type: null,
      icId: null,
      icList: [],
      name: null,
      description: null,
      unitData: {
        name: null,
        description: null,
        type: null,
        icId: null,
        regNo: null,
        noOfSeats: null,
      },
      assignDialog: false,
      removeDialog: false,
      empid: null,
      empList: null,
    };
  },
  beforeMount() {
    this.getData();
    this.getTypes();
    this.getIc();
    this.getEmp();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/each/unit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.unitData = response.data.data;
          if (response.data.data.unitUnderIc) {
            this.unitData.icId = response.data.data.unitUnderIc;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    assign() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/assign/employee/adminv1",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          unitId: this.unitData._id,
          empId: this.empid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;

          this.showSnackBar = true;
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    remove(id) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/remove/employee",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          unitId: this.unitData._id,
          empId: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;

          this.showSnackBar = true;
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getTypes() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/view/unit/types",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.types = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getIc() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/view/all/ic",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.icList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmp() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/get/all/employeelist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
input {
  color: black !important;
}
</style>
